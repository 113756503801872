@import "@/assets/scss/style.scss";

.automatic-events-wrapper {
    margin: 5rem auto;
    width: 70%;
    max-height: 70vh;
    overflow: auto;
    border: 1px solid rgb(212, 212, 212);
    @media (max-width: 1400px) {
        width: 80%;
        max-height: 80vh;
    }
    @media (max-width: 1100px) {
        width: 90%;
    }
    @media (max-width: 800px) {
        width: 95%;
    }
    .statuses__top-bar {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin: 0.5rem;

        @media (max-width: 650px) {
            flex-direction: column;
        }

        button,
        span {
            cursor: pointer;
            font-size: 1rem;
        }
        .automatic-events__span-wrapper {
            display: flex;
            align-items: center;
            justify-self: center;
            margin-left: 40%;

            @media (max-width: 650px) {
                margin: 0 0 1rem 0;
            }

            & > span:first-child {
            }
            & > span:nth-child(2) {
            }
            .divider {
                margin: 0 0.5rem;
                height: 20px;
                width: 1px;
                background-color: #000;
            }
            .is-active {
                color: #000;
                border-bottom: 1px solid #000;
            }
        }
        .automatic-events__button-wrapper {
            margin-left: auto;
            padding-left: 0.5rem;

            @media (max-width: 650px) {
                margin: 0;
            }
            .statuses__delete-button {
                margin-left: 0.5rem;
            }
        }
        button {
            font-size: 1rem;
        }
    }
    .automatic-events-content {
        overflow-x: auto;
        padding: 2rem;

        .statuses-table-wrapper {
            min-width: max-content;
        }
    }
    .delete-icon {
        font-size: 20px;
        color: red;
    }
}

.events__table-row {
    cursor: pointer;
}
