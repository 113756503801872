@import "@/assets/scss/style.scss";

.status-wrapper {
    width: fit-content;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    padding: 1rem 3rem 3rem;
    min-width: 70%;
    max-height: 80vh;

    & > * {
        width: 100%;
    }

    @media (max-width: 1100px) {
        padding: 2rem;
        flex-wrap: wrap;
        flex-direction: row;
        overflow: auto;
    }

    @media (max-width: 550px) {
        padding: 1rem;
    }

    .status__form-field {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 2rem;
        width: 100%;

        &:last-of-type {
            margin: 0;
        }

        label,
        span {
            // font-weight: bold;
            font-size: 1rem;
            color: #000;
        }

        .status-name__title {
            margin-right: 1rem;
            white-space: nowrap;
        }

        .radio-item-style {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 30px;
            line-height: 30px;
        }
    }

    .status-option-field {
        border-bottom: 1px solid rgb(221, 221, 221);
        border-radius: 5px;
        padding-bottom: 15px;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        & > * {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .status__main-button {
        align-self: center;
        // font-size: 1.2rem;
        width: fit-content;
    }

    .labels-select {
        width: 100%;
    }

    .popup-title {
        margin-bottom: 2rem;
    }

    .email-wrapper {
        display: flex;
        flex-direction: column;
        .email-title {
            margin-bottom: 2rem;
            text-align: center;
        }

        .email-fields-wrapper {
            display: flex;
            flex-direction: row;
            width: 100%;

            @media (max-width: 1000px) {
                flex-direction: column;
                align-items: center;
            }
            .status__email-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                margin-right: 2rem;

                @media (max-width: 1000px) {
                    margin-right: 0;
                }

                .status__email-field {
                    margin-bottom: 1rem;
                }
            }
            .status__email-field {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                flex-direction: column;
                border: 1px solid rgb(221, 221, 221);
                border-radius: 5px;
                padding: 15px;
                margin: 15px;
                width: 100%;
                height: auto;

                @media (max-width: 1000px) {
                    width: fit-content;
                }

                @media (max-width: 550px) {
                    //  width: 100%;
                }

                &:first-child {
                    // margin-right: 2rem;
                }

                .time-unit-title__helper {
                    font-size: 0.9rem;
                    color: rgb(84, 84, 84);
                }

                .email-details__form-field {
                    width: 100%;

                    &:first-of-type {
                        margin-bottom: 1rem;
                    }
                }

                input {
                    color: rgb(54, 54, 54);
                    margin-top: 0.5rem;
                }

                .email-field-title {
                    margin-bottom: 1.5rem;
                    padding-bottom: 0.2rem;
                    width: 100%;
                    font-size: 1rem;
                    border-bottom: 1px solid rgb(221, 221, 221);
                    text-align: center;
                }

                .ant-checkbox-group {
                    display: flex;
                    flex-direction: column;
                    gap: 0.2rem;
                }
            }
        }
    }
}

.required {
    color: #f5222d !important;
}
