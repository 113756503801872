@import "@/assets/scss/style.scss";

.activity-form__wrapper {
    width: 60%;

    .activity-label {
        font-size: 1rem;
        margin-bottom: 0.5rem;
        color: #000;
        display: block;
    }

    .activity-field {
        margin-bottom: 1rem;
        width: 100%;

        .activity-select {
            width: 100%;
        }
    }
}

.required {
    color: #f5222d !important;
}
